<template >
    <v-row
        id="footer"
        justify="center" 
        align="center">
        <v-footer 
            style="
            background: -webkit-linear-gradient(to top, #01579B,#01579B,#0277BD, #0277BD, #0288D1, #039BE5,#29B6F6);
            background: linear-gradient(to top, #01579B,#01579B,#0277BD, #0277BD, #0288D1, #039BE5,#29B6F6);
            "
            padless
            width="100%"
            >
            <v-col 
                class="text-center mt-5" 
                justify="center" 
                align="center" 
                cols="12">
                <h1 class="display-2 font-weight-bold mb-3">
                    Contactez Nous!
                </h1>
            </v-col>
            <v-row
                class="mx-auto mt-5"
                justify="center"
                >
               
                
                <v-col 
                :class="[$vuetify.breakpoint.smAndDown ? 'text-center': '']"
                class="ml-5 mx-auto"
                    cols="12"
                    md="2">
                    <h4 class="mb-2 white--text">
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fas fa-map-marker-alt
                        </v-icon>
                            Résidence Enadjah, 3 Freres Bouadou Rd, Bir Mourad Raïs, Algeria
                    </h4>
                    <h4 class="mb-2 white--text">
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fas fa-mobile-alt
                        </v-icon> 
                        0657 96 96 84 / 0657 95 95 06
                    </h4>
                    <h4 class="mb-2 white--text">
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fas fa-phone
                        </v-icon> 
                        023 54 00 02 / 023 54 00 14
                    </h4>
                    <h4 class="mb-2 white--text">
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>far fa-envelope
                        </v-icon>
                            <a href="mailto:efamformation16@gmail.com" style="color:white">efamformation16@gmail.com</a>
                    </h4>
                </v-col>


                <v-col class="text-center mx-auto" cols="12" md="7">
                    <v-btn
                        v-for="link in links"
                        :key="link"
                        color="white"
                        text
                        rounded
                        class="my-2"
                        :href=" link.target "
                        >
                        {{ link.title }}
                    </v-btn><br>
                </v-col>

                <v-col 
                    :class="[$vuetify.breakpoint.smAndDown ? 'text-center': '']"
                    class=" mx-auto"
                    cols="12"
                    md="2">
                    <h4 class="mb-4"> 
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fab fa-facebook
                        </v-icon>
                        <a href="https://www.facebook.com/EFAM-106141348607382" style="color:white"> Facebook</a>
                    </h4>
                    <h4 class="mb-4"> 
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fab fa-instagram
                        </v-icon>
                        <a href="https://www.instagram.com/efamecole/" style="color:white"> Instagram</a>
                    </h4>
                    <h4 class="mb-4"> 
                        <v-icon 
                            class="mr-2 white--text"
                            size="25"
                            dense>fab fa-twitter
                        </v-icon>
                        <a href="twitter.com" style="color:white"> Twitter</a>
                    </h4>
                </v-col>
                <v-col
                    style="background-color:#01579B"
                    class=" py-2 text-center white--text"
                    cols="12"
                >
                    <h4>{{ new Date().getFullYear() }} — Créé par<a class="text-weight-bold grey--text text--white" href="https://youby07.github.io" target="_blank"> HABBOU Ayoub</a></h4>  
                </v-col>
            </v-row>
        </v-footer>
    </v-row>
</template>

<script>
export default {
    data: () => ({
    name: 'Footer',
      links: [
        {
            title:'Accueil',
            target:'/'
        },
        {
            title:'Contact',
            target:'#contact'
        }
      ],
    }),
}
</script>